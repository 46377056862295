import * as d3 from "d3";

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/tree
export default function Tree(data, { // data is either tabular (array of objects) or hierarchy (nested objects)
   path, // as an alternative to id and parentId, returns an array identifier, imputing internal nodes
   id = Array.isArray(data) ? d => d.id : null, // if tabular data, given a d in data, returns a unique identifier (string)
   parentId = Array.isArray(data) ? d => d.parentId : null, // if tabular data, given a node d, returns its parent’s identifier
   children, // if hierarchical data, given a d in data, returns its children
   tree = d3.tree, // layout algorithm (typically d3.tree or d3.cluster)
   sort, // how to sort nodes prior to layout (e.g., (a, b) => d3.descending(a.height, b.height))
   label, // given a node d, returns the display name
   title, // given a node d, returns its hover text
   link, // given a node d, its link (if any)
   linkTarget = "_blank", // the target attribute for links (if any)
   width = 1200, // outer width, in pixels
   height = 800, // outer height, in pixels
   r = 3, // radius of nodes
   padding = 1, // horizontal padding for first and last column
   fill = "#999", // fill for nodes
   fillOpacity, // fill opacity for nodes
   stroke = "#111", // stroke for links
   strokeWidth = 1.5, // stroke width for links
   strokeOpacity = 0.8, // stroke opacity for links
   strokeLinejoin, // stroke line join for links
   strokeLinecap, // stroke line cap for links
   halo = "#fff", // color of label halo 
   haloWidth = 3, // padding around the labels
 } = {}) {
 
   // If a path accessor is specified, we can impute the internal nodes from the slash-
   // separated path; otherwise, the tabular data must include the internal nodes, not
   // just leaves. TODO https://github.com/d3/d3-hierarchy/issues/33
   if (path != null) {
     const D = d3.map(data, d => d);
     const I = d3.map(data, path).map(d => (d = `${d}`).startsWith("/") ? d : `/${d}`);
     const paths = new Set(I);
     for (const path of paths) {
       const parts = path.split("/");
       while (parts.pop(), parts.length) {
         const path = parts.join("/") || "/";
         if (paths.has(path)) continue;
         paths.add(path); I.push(path); D.push(null);
       }
     }
     id = (_, i) => I[i];
     parentId = (_, i) => I[i] === "/" ? "" : I[i].slice(0, I[i].lastIndexOf("/")) || "/";
     data = D;
   }
   // If id and parentId options are specified (perhaps implicitly via the path option),
   // use d3.stratify to convert tabular data to a hierarchy; otherwise we assume that
   // the data is specified as an object {children} with nested objects (a.k.a. the
   // “flare.json” format), and use d3.hierarchy.
   const root = id == null && parentId == null
     ? d3.hierarchy(data, children)
     : d3.stratify().id(id).parentId(parentId)(data);
 
   // Compute labels and titles.
   const descendants = root.descendants();
   const L = label == null ? null : descendants.map(d => label(d.data, d));
 
   // Sort the nodes.
   if (sort != null) root.sort(sort);
 
   // Compute the layout.
   const dx = width/2.5;
   const dy = height/2;
   tree().nodeSize([dx, dy])(root);
 
   // Center the tree.
   let x0 = Infinity;
   let x1 = -x0;
   root.each(d => {
     if (d.x > x1) x1 = d.x;
     if (d.x < x0) x0 = d.x;
   });
 
   // Compute the default height.
   if (height === undefined) height = x1 - x0 + dx * 2;

   const u = (width<500)?15:(width<1000)?25:45;
 
   const svg = d3.create("svg")
     .attr("viewBox", [-width*2, height/2, width*3.2, height])
     .attr("width", width)
     .attr("height", height)
     .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
     .attr("font-family", "sans-serif")
     .attr("font-size", width<500?27:width<1000?40:70)
     .style("border", "1px solid black");
 
   svg.append("g")
     .attr("fill", "none")
     .attr("stroke", stroke)
     .attr("stroke-opacity", strokeOpacity)
     .attr("stroke-linecap", strokeLinecap)
     .attr("stroke-linejoin", strokeLinejoin)
     .attr("stroke-width", strokeWidth)
     .selectAll("path")
     .data(root.links())
     .join("path")
     .attr("d", d3.linkVertical()
       .x(d => d.x)
       .y(d => d.y));
 
   const node = svg.append("g")
     .selectAll("a")
     .data(root.descendants())
     .join("a")
     .attr("xlink:href", d => (d.data.link[0]=="#"?`javascript:document.getElementById("${d.data.link.substring(1)}").scrollIntoView()`:d.data.link))
     .attr("transform", d => `translate(${d.x},${d.y})`);
 
   node.append("circle")
     .attr("fill", d => d.children ? stroke : fill)
     .attr("r", r);
 
   if (title != null) node.append("title")
     .text(d => title(d.data, d));
 
   if (L) node.append("text")
     .attr("y", -3*u)
     .attr("x", 0)
     .attr("text-anchor", "middle")
     .attr("font-weight", "bold")
     .style("fill", "blue")
     .text((d, i) => L[i].split("\n")[0])
     .call(text => text.clone(true))
     .attr("fill", "none")
     .attr("stroke", halo)
     .attr("stroke-width", haloWidth);
 
   if (L) node.append("text")
     .attr("y", -u)
     .attr("x", 0)
     .attr("text-anchor", "middle")
     .attr("font-weight", "bold")
     .style("fill", "blue")
     .text((d, i) => L[i].split("\n")[1])
     .call(text => text.clone(true))
     .attr("fill", "none")
     .attr("stroke", halo)
     .attr("stroke-width", haloWidth);
 
     if (L) node.append("text")
     .attr("y", 2*u)
     .attr("x", 0)
     .attr("text-anchor", "middle")
     .attr("font-weight", "bold")
     .style("fill", "brown")
     .text((d, i) => L[i].split("\n")[2])
     .call(text => text.clone(true))
     .attr("fill", "none")
     .attr("stroke", halo)
     .attr("stroke-width", haloWidth);
 
   if (L) node.append("text")
     .attr("y", 4*u)
     .attr("x", 0)
     .attr("text-anchor", "middle")
     .attr("font-weight", "bold")
     .style("fill", "brown")
     .text((d, i) => L[i].split("\n")[3])
     .call(text => text.clone(true))
     .attr("fill", "none")
     .attr("stroke", halo)
     .attr("stroke-width", haloWidth);
      
   if (L) node.append("text")
     .attr("y", 2.1*u)
     .attr("x", 0)
     .attr("text-anchor", "middle")
     .attr("font-weight", "bold")
     .style("fill", "black")
     .text((d, i) => L[i].split("\n")[4])
     .call(text => text.clone(true))
     .attr("fill", "none")
     .attr("stroke", halo)
     .attr("stroke-width", haloWidth);

   if (L) node.append("text")
     .attr("y", ((width*1.5<height)?3.2*u:2.1*u))
     .attr("x", 0)
     .attr("text-anchor", "middle")
     .attr("font-weight", "bold")
     .style("fill", "black")
     .text((d, i) => L[i].split("\n")[5])
     .call(text => text.clone(true))
     .attr("fill", "none")
     .attr("stroke", halo)
     .attr("stroke-width", haloWidth);

   return svg.node();
 }
 