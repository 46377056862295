import bg from './bg.png';
import weibo from './weibo.png';
import twitter from './twitter.png';
import tumblr from './tumblr.png';
import linkedin from './linkedin.png';
import instagram from './instagram.png';
import facebook from './facebook.png';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Navbar, NavbarToggler, Collapse, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import chart_hosting from "./chart_hosting.json";
import chart_front from "./chart_front.json";
import chart_back from "./chart_back.json";
import chart_cms from "./chart_cms.json";
import chart_xml from "./chart_xml.json";
import chart_mobile from "./chart_mobile.json";
import chart_os from "./chart_os.json";
import chart_ai from "./chart_ai.json";
import {useState, useCallback, useRef, useEffect} from "react";
import QATree from "./tree_QA";
import TechnologyTree from "./tree_technology";
import ReactFBLike from 'react-fb-like';

function App() {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const svg_hosting = useRef(null);
  const svg_front = useRef(null);
  const svg_back = useRef(null);
  const svg_cms = useRef(null);
  const svg_xml = useRef(null);
  const svg_mobile = useRef(null);
  const svg_os = useRef(null);
  const svg_ai = useRef(null);
  useEffect(() => {
    const charts = [chart_hosting, chart_front, chart_back, chart_cms, chart_xml, chart_mobile, chart_os, chart_ai];
    const svgs = [svg_hosting, svg_front, svg_back, svg_cms, svg_xml, svg_mobile, svg_os, svg_ai];
    const trees = [QATree, TechnologyTree, TechnologyTree, TechnologyTree, TechnologyTree, TechnologyTree, TechnologyTree, TechnologyTree,];
    function resize() {
      for (let i = 0; i < 8; i++) {
        const chart = trees[i](charts[i], {
          label: d => d.name,
          width: window.innerWidth,
          height: window.innerHeight *1.5,
          title: (d, n) => d.title, // hover text
          link: (d, n) => d.link
        });
        svgs[i].current.innerHTML = null;
        svgs[i].current.appendChild(chart);
        document.querySelectorAll("section>div:first-child")[i].style.fontSize = (window.innerHeight<window.innerWidth)?Math.sqrt(window.innerWidth*.3*window.innerHeight)/43+"px"
                                                                                                                       :Math.sqrt(window.innerWidth*window.innerHeight)/43+"px";
      }
      document.querySelector("main").style.fontSize = Math.sqrt(window.innerWidth*.18*window.innerHeight)/22+"px";
      //document.querySelector("meta[name=viewport]").setAttribute("content", (window.innerWidth < window.innerHeight)?"width=device-width, initial-scale = 0.86, maximum-scale=3.0, minimum-scale=0.86":"width=device-width, initial-scale = 1, maximum-scale=3.0, minimum-scale=1");
      forceUpdate();
    }
    resize();
    window.onresize = resize;
  }, [forceUpdate]);

  return (
    <div className="App">
      <section style={{ background: `url(${bg}) fixed`, backgroundSize: `100% 100%` }}>
        <a href="/"><h1 style={(window.innerWidth < 770) ? { height: "12vh", lineHeight: "12vh" } : (window.innerWidth < 950) ? { height: "8vh", lineHeight: "8vh", fontSize: "6vw !important" } : { height: "6vh", lineHeight: "6vh" }}>Web Coding Center</h1></a>
        {(window.innerWidth < 770 || window.innerWidth<window.innerHeight) ? "" :
          <Navbar color="white-50" expand="md" light style={{ background: "rgba(240, 240, 240, 0.85)", borderRadius: "20px", left: "5vw", top: "15vh", paddingLeft: "2vw", paddingTop: "0.5vw", height: "2vw", width: "90vw" }}>
            <NavbarToggler onClick={function noRefCheck() { }} />
            <Collapse navbar>
              <Nav className="me-auto" navbar>
                <UncontrolledDropdown inNavbar nav>
                  <DropdownToggle caret nav style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vw" }}>{(window.innerWidth < window.innerHeight) ? "Hosting" : "Web Hosting"}</DropdownToggle>
                  < DropdownMenu end style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vh", textAlign: "center"  }}>
                    <DropdownItem href=" /development-environments">Development Environments</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown inNavbar nav style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vw" }}>
                  <DropdownToggle caret nav >CMS</DropdownToggle>
                  < DropdownMenu end style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vh", textAlign: "center"  }}>
                    <DropdownItem href="#cms">CMS</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown inNavbar nav style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vw" }}>
                  <DropdownToggle caret nav >{(window.innerWidth < 1420) ? "Front-End" : "Front-End Web Development"}</DropdownToggle>
                  < DropdownMenu end style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vh", textAlign: "center" }}>
                    <DropdownItem text-center href="/html">HTML</DropdownItem>
                    <DropdownItem href="/css">CSS</DropdownItem>
                    <DropdownItem href="/bootstrap">Bootstrap</DropdownItem>
                    <DropdownItem href="/seo">SEO</DropdownItem>
                    <DropdownItem href="/svg">SVG</DropdownItem>
                    <DropdownItem href="/less">LESS</DropdownItem>
                    <DropdownItem href="/sass">SASS</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/ecmascript">JavaScript - ECMAScript 2020</DropdownItem>
                    <DropdownItem href="/html-dom">HTML DOM</DropdownItem>
                    <DropdownItem href="/web-apis">Advanvced Web APIs</DropdownItem>
                    <DropdownItem href="/jquery">JQuery</DropdownItem>
                    <DropdownItem href="/react">React</DropdownItem>
                    <DropdownItem href="/redux">Redux</DropdownItem>
                    <DropdownItem href="/extensions-and-tools">Extensions and Tools</DropdownItem>
                    <DropdownItem href="/canvas">2D Canvas</DropdownItem>
                    <DropdownItem href="/webgl">WebGL</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown inNavbar nav style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vw" }}>
                  <DropdownToggle caret nav >{(window.innerWidth < 1420) ? "Back-End" : "Back-End Web Development"}</DropdownToggle>
                  < DropdownMenu end style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vh", textAlign: "center"  }}>
                    <DropdownItem href="/php">PHP</DropdownItem>
                    <DropdownItem href="/sql">MySQL</DropdownItem>
                    <DropdownItem href="/next.js">Next.js</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown inNavbar nav style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vw" }}>
                  <DropdownToggle caret nav >{(window.innerWidth < 970) ? "Mobile" : "Mobile App Development"}</DropdownToggle>
                  < DropdownMenu end style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vh", textAlign: "center"  }}>
                    <DropdownItem href="/cordova">Cordova</DropdownItem>
                    <DropdownItem href="/react-native">React Native</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown inNavbar nav style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vw" }}>
                  <DropdownToggle caret nav >XML</DropdownToggle>
                  < DropdownMenu end style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vh", textAlign: "center"  }}>
                    <DropdownItem href="/xml">XML</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown inNavbar nav style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vw" }}>
                  <DropdownToggle caret nav >{(window.innerWidth < 800) ? "OS" : "Operating System"}</DropdownToggle>
                  < DropdownMenu end style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vh", textAlign: "center"  }}>
                    <DropdownItem href="/docker">Docker</DropdownItem>
                    <DropdownItem href="/aws">AWS</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown inNavbar nav style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vw" }}>
                  <DropdownToggle caret nav >{(window.innerWidth < 1120) ? "AI" : "Artificial Intelligence"}</DropdownToggle>
                  < DropdownMenu end style={{ fontSize: "min(2vw, 1em)", lineHeight: "2vh", textAlign: "center"  }}>
                    <DropdownItem href="#ai">Artificial Intelligence</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Navbar>}
        <main>
          In this digital age, software skills boost productivity and help you to succeed in your industry. According to the U.S. Bureau Of Labor Statistics, the employment of software engineers is projected to grow by more than 20 percent in the coming decade. This website guides you to various aspects of modern Information Technology with free tutorials.
        </main>
        <div className="nav">
          <h3> Master The Art of Computing:</h3>
          <ul>
            <li><a href="#hosting">Web Hosting</a></li>
            <li><a href="#cms">CMS</a></li>
            <li><a href="#front">Front-End Web Development</a></li>
            <li><a href="#back">Back-End Web Development</a></li>
            <li><a href="#xml">XML</a></li>
            <li><a href="#mobile">Mobile App Development</a></li>
            <li><a href="#os">Operating System &amp; Cloud</a></li>
            <li><a href="#ai">Artificial Intelligence</a></li>
          </ul>
        </div>
        <nav>
          <a target="_blank" href="https://www.facebook.com/webcodingcenter"><img src={facebook} /></a>
          <a target="_blank" href="https://twitter.com/webcodingcenter"><img src={twitter} /></a>
          <a target="_blank" href="https://www.linkedin.com/in/web-coding-center-8a1353226/detail/recent-activity/"><img src={linkedin} /></a>
          <a target="_blank" href="https://www.instagram.com/webcodingcenter/"><img src={instagram} /></a>
          <a target="_blank" href="https://www.tumblr.com/blog/web-coding-center"><img src={tumblr} /></a>
          <a target="_blank" href="https://weibo.com/u/7724791178"><img src={weibo} /></a>
        </nav>
        <footer>
          <div>
            <a href="mailto:webmaster@webcodingcenter.com">webmaster@webcodingcenter.com</a>&nbsp; &nbsp; &nbsp;
            <wbr />Last Update: <time dateTime="2023-11-26">26 Nov 2023</time>
          </div>
          <div>
            © All rights reserved.  &nbsp; &nbsp; 
            <ReactFBLike appId="1399772917172608" version="v12.0" />
          </div>
        </footer>
      </section>
      <section id="hosting" style={{ backgroundColor: "rgba(100,100,0)" }}>
        <div style={{ color: "white" }}><h2 style={{ color: "yellow" }}>Web Hosting</h2>
          <div>To host a website with a domain name you desire (such as <i>mydomain.com</i>), you should first decide where to store the files of the server, the central computer serving the public.</div><br />
          <div>Setting up the server in your own premises can be cheaper in the long run. However, if you don't want to purchase all the hardware upfront and continuously maintain the server from top to bottom, you can choose to rent a remote server in a cloud network.</div><br />
          <div>There are three levels of cloud computing:</div>
          <ol style={{ paddingLeft: "3vmax" }}>
            <li><b>IaaS</b>: Infrastructure-as-a-Service<br />Manages the application development and operating system.<br /><i>eg. Amazon Web Services(AWS) - EC2, Microsoft Azure, Google Compute Engine (GCE)</i></li>
            <li><b>PaaS</b>: Platform-as-a-Service<br />Manages the application development only.<br /><i>eg. AWS Elastic Beanstalk, Windows Azure, Google App Engine</i></li>
            <li><b>SaaS</b>: Software-as-a-Service<br />Uses the application only.<br /><i>eg. wordpress.com, Google Drive, Zoom, Netflix, Dropbox, Google Apps</i></li>
          </ol>
          <br />
          <div>You should also decide whether to use a Content Management System such as Wordpress. A CMS is a website builder that allows you to create and maintain simpler websites right away without having to code. SEO-friendly and mobile-friendly templates are provided out of the box.</div><br />
          <div>Note that, although the diagram does not show this, you can also use a CMS with an IaaS provider, and that a PaaS web host can be shared or dedicated too.</div>
        </div>
        <div ref={svg_hosting}></div>
      </section>
      <section id="cms" style={{ backgroundColor: "rgba(100,0,0)" }}>
        <div><h2>CMS</h2>
          <div>A Content Management System (CMS) is a website builder that allows you to quickly create and manage SEO-friendly and mobile-friendly websites on a browser. If you don't want to code the front end and back end, and are fine with the limitations, you can turn to a CMS.</div><br />
          <div><b>WordPress</b>, originally a blogging system, with its massive ecosystem of plugins, has become the king of CMS. You can:
            <ul>
              <li>pay to use hosted WordPress directly on <i>wordpress.com</i>.</li>
              <li>pay to use hosted WordPress indirectly on a third-party web host such as FastComet and Hostinger.</li>
              <li>freely use WordPress on your own server by hosting it yourself, ie. <i>wordpress.org</i>.</li>
            </ul>
          </div>
          <div><b>Wix</b> is not as old as WordPress but is fast gaining popularity. With its drag-and-drop interface, it is somewhat easier to use than WordPress, and offers better support in case you need help.</div>
        </div>
        <div ref={svg_cms}></div>
      </section>
      <section id="front" style={{ backgroundColor: "purple" }}>
        <div><h2>Front-End Web Development</h2>
          <div>Focusing on the end users' interactions with a website on a browser, front-end web development is a science as much as an art. An accomplished front-end web developer has a profound understanding of how to make browsers display web pages that are user-friendly and visually appealing. To these ends, the developer needs to have both a knowledgeable and sharp mind, as well as a creative and aesthetic spirit. Without any of the two, the developer is destined to fail in his or her job.</div><br />
          <div>The basis of the World Wide Web, <b>HTML</b> defines the underlying structure of a webpage.</div><br />
          <div><b>CSS</b> specifies how HTML elements are displayed and animated. It is used to make web pages responsive (able to look nice with different screen dimensions), a very important principle of modern web design. <b>Bootstrap</b> is the most popular CSS framework.</div><br />
          <div><b>JavaScript</b> is the programming language used to execute programs on all modern browsers.</div><br />
          <div>As of Nov 2021, <b>React</b> is the most popular front-end JavaScript framework, often a requirement for a web development job. Organizing the user interface into a hierarchy of reusable components that are updated intelligently, React enhances and expedites the process of web development.</div><br />
          <div>An unnoticed website is of no use to anyone. In order to successfully deliver the objectives of a website, it is important to drive organic traffic to the website with the techniques of <b>SEO</b>.</div>
        </div>
        <div ref={svg_front}></div>
      </section>
      <section id="back" style={{ backgroundColor: "green" }}>
        <div><h2>Back-End Web Development</h2>
          <div>Back-end web development deals with servers, the central computers serving the public visitors. A web server is responsible for storing and serving webpages and the associated assets, whereas a database server efficiently stores and processes the users' data.</div><br />
          <div><b>LAMP</b> (<b>L</b>inux - <b>A</b>pache - <b>M</b>ySQL - <b>P</b>HP) is a very popular technology stack. Another popular stack of technologies used together is <b>MERN</b> (<b>M</b>ongoDB - <b>E</b>xpress - <b>R</b>eact - <b>N</b>ode).</div><br />
          <div>A developer that does both front-end and back-end web development is called a full-stack web developer.</div><br />
          <div>This diagram is by no means exhaustive. For instance, it is possible to use PHP with IIS or Node.js. Some technologies have also been omitted in the diagram, such as the other web frameworks (eg. Flask, CherryPy, TurboGears, Web2Py) of Python, and the other lesser-known DBMS.</div><br />
        </div>
        <div ref={svg_back}></div>
      </section>
      <section id="xml" style={{ backgroundColor: "rgb(50,50,50)" }}>
        <div><h2>XML</h2>
            <div><b>XML</b> represents a way to store data in a human-readable and machine-readable format. Inherently hierarchical, an XML document resembles an HTML document, except that the tag and attribute names are not pre-defined. With XML, data can be easily prepared, transformed and transported.</div><br/>
            <div><b>XPath</b> is a language for selecting nodes from an XML document. It is used in XQuery, XSLT, XPointer, XInclude, XSD, Schematron, XForms etc.</div><br/>
            <div><b>XSLT</b> is a language for transforming XML documents. XSLT templates are themselves XML documents. The functionalities of XSLT overlap with those of <b>XQuery</b>, a funtional languange primarily used to query a large collection of XML documents.</div><br/>
            <div><b>Schema languages</b> define the permissible grammar of an XML document. They are used to validate XML documents.</div><br/>
            <div><b>XSL-FO</b> is a markup language for XML document formatting that is most often used to generate PDF files.</div><br/>
        </div>
        <div ref={svg_xml}></div>
      </section>
      <section id="mobile" style={{ backgroundColor: "blue" }}>
        <div><h2>Mobile App Development</h2>
          <div>Mobile apps  can work offline on mobile devices. They can be uploaded to app stores such as Google Play Store, Apple App Store and Huawei App Gallery.</div><br/>
          <div><b>Native</b> app development targets one specific mobile platform (Android or Apple) at one time only. Coding an app natively means having direct access to all the low-level APIs exposed by the mobile platform. <b>Android Studio</b> is primarily the IDE for Android/Huawei apps, while <b>XCode</b> is generally used to code iOS Swift Applications.</div><br/>
          <div><b>Hybrid</b> app development targets multiple platforms at once with a single codebase. Sometimes this includes desktop environments such as Windows and Mac. JavaScript is commonly used as the programming language along with HTML and CSS. Plugins can be installed to access various functionalities of mobile devices. </div><br/>
        </div>
        <div ref={svg_mobile}></div>
      </section>
      <section id="os" style={{ backgroundColor: "brown" }}>
        <div><h2>Operating System &amp; Cloud</h2>
            <div>When it comes to programming operating systems, <b>C</b> is a natural choice.</div><br/>
            <div>There are three major variants of OS in use today: <b>Microsoft Windows</b>, <b>Linux</b>, and <b>Apple MacOS</b>. Of these, Linux is free and open-source software.</div><br/>
            <div>Cloud service providers such as <b>Amazon Web Services (AWS)</b>, <b>Microsoft Azure</b> and <b>Google Cloud</b> allow you to rent a remote server, thus saving you the hassle of setting up and maintaining the server.</div><br/>
            <div><b>Docker</b> is an open-source containerization platform. It enables developers to package applications into containers—standardized executable components combining application source code with the operating system (OS) libraries and dependencies required to run that code in any environment.</div><br/>
        </div>
        <div ref={svg_os}></div>
      </section>
      <section id="ai" style={{ backgroundColor: "rgba(0,100,100)" }}>
        <div><h2>Artificial Intelligence</h2>
           <div>A part of artificial intelligence, <b>machine learning</b> is the study of computer algorithms that can improve automatically through experience and by the use of data. In supervised learning, to train a model, a collection of multi-variable inputs are supplied to an estimator, along with the corresponding output for each input. After the model has been successfully trained, predictions can be made from other inputs.</div><br/>
           <div>Estimators predicting discrete classes are called <b>classifiers</b>; whereas estimators predicting continuous values are called <b>regressors</b>.</div><br/>
           <div><a href="https://www.kaggle.com/"><span style={{color:"yellow"}}>Kaggle</span></a>, a subsidiary of Google LLC, is an online community of data scientists and machine learning practitioners. You can win cash prizes by taking part in its competitions hosted by companies.</div><br/>
        </div>
        <div ref={svg_ai}></div>
      </section>
    </div>
  );
}


export default App;
